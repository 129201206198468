export default function() {
  return [
    {
      vname: 'Текущая версия',
      fullname: 'Ноeeeвиков Николай',
      date: '10.05.2021',
      time: '15:00',
    },
    {
      vname: 'Версия 5',
      fullname: 'Болатов Ерлан',
      date: '10.05.2021',
      time: '15:00',
    },
    {
      vname: 'Версия 4',
      fullname: 'Жансугуров Елбай',
      date: '10.05.2021',
      time: '15:00',
    },
    {
      vname: 'Версия 3',
      fullname: 'Костин Никита',
      date: '10.05.2021',
      time: '15:00',
    },
    {
      vname: 'Версия 2',
      fullname: 'Арнольд Светлана',
      date: '10.05.2021',
      time: '15:00',
    },
  ]
}
